<template>
  <div dir="rtl">
    <div class="background"></div>
    <div class="card absolute main_container">
      <div class="flex flex_justify_between login_container">
        <div class="flex flex_align_center flex_justify_center" style="text-align: center; width: 50%; padding: 30px;">
          <div>
            <p><b><u>Login</u></b></p>
            <div class="flex flex_align_center flex_direction_column" style="margin-top: 25px;">
              <input v-model="email" placeholder="אימייל">
              <div class="vertical_space"></div>
              <div class="vertical_space"></div>
              <input v-model="password" type="password" placeholder="סיסמא">
              <div class="vertical_space"></div>
              <div class="vertical_space"></div>
              <div class="vertical_space"></div>
              <div class="flex flex_justify_center">
                <a @click="login" class="button is-info" :class="{ 'is-loading': is_loading }">Submit</a>
              </div>
            </div>
          </div>
        </div>
        <div class="relative logo_container" dir="ltr">
          <div class="operation_theme"></div>
          <img :src="$settings.get({ path: 'images' }).logo_bright" width="120px" />
          <div class="separator"></div>
          <p><b>Welcome to SuitUp stargate panel :)</b></p>
          <p>Please type your email and password to login.</p>
          <p class="absolute" style="color: #999; bottom: 25px;">For any problems, please ask for one of the development team members' help.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data () {
    return {
      is_loading: false,
      email: '',
      password: ''
    }
  },
  created () {
    if (this.$utils.isLoggedIn()) {
      this.$router.push({ path: '/' })
    }
  },
  methods: {
    login () {
      this.is_loading = true
      this.$network_utils.login({ email: this.email, password: this.password }).then(response => {
        localStorage.setItem('auth', response.auth_token)
        this.$router.push({ path: '/' })
      }).catch(() => {
        this.is_loading = false
        this.$utils.emit('show_toast', { text: 'ארעה שגיאה - הרשאות אינן תקינות' })
      })
    }
  }
}
</script>
<style scoped>
	.card{
		padding: 0;
		margin: 0;
	}
	.logo_container {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		width: 50%;
		padding: 30px;
		background-color: var(--color-main);
		color: white;
	}
	img{
		display: block;
	}
	input{
		border: 1px solid var(--color-main);
		color: var(--color-main);
	}
	.operation_theme{
		position: absolute;
		opacity: 0.4;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
	}
	.button{
		border-radius: 30px;
	}
	.separator{
		width: 150px;
		border-radius: 30px;
		height: 3px;
		margin-top: 15px;
		margin-bottom: 15px;
		background-color: white;
	}
	.login_container{
		width: 870px;
		height: 510px;
	}
	.main_container{
		top: 46%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.background{
		position: fixed;
		z-index: 0;
		width: 100vw;
		height: 100vh;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.1);
	}
</style>
